import React from 'react';
import Map from 'components/map/GoogleMap'

const SectionMap = ({children}) => {
  return <div className="absolute inset bottom-0 w-full h-full bg-gray-300">
    <Map />
    {children}
  </div>
}

export default SectionMap;
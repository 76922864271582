// SvgComponent.jsx
import React, { useState, useEffect } from 'react';

async function loadSvgAsString(svgPath) {
  try {
    const response = await fetch(svgPath);
    const svgText = await response.text();
    
    // SVG 문자열에서 svg 태그 부분만 추출
    const svgMatch = svgText.match(/<svg[^>]*>[\s\S]*<\/svg>/);
    return svgMatch ? svgMatch[0] : null;
  } catch (error) {
    console.error('SVG 로드 실패:', error);
    return null;
  }
}

const SVGIcon = ({ svgPath, className, style, svgStyle={width:"100%",height:"100%"}, color="#000000", activeColor = "#e6ff00" }) => {
  const [svgContent, setSvgContent] = useState('');

  useEffect(() => {
    const loadSvg = async () => {
      const svg = await loadSvgAsString(svgPath);
      if (svg) {
        // SVG 파싱
        const parser = new DOMParser();
        const doc = parser.parseFromString(svg, 'image/svg+xml');
        const svgElement = doc.querySelector('svg');

        // fill 속성이 있는 모든 요소에 color 적용
        svgElement.querySelectorAll('[fill]:not([fill="none"])').forEach(el => {
          el.setAttribute('fill', color);
        });

        // fill 속성이 없는 path, circle, rect 등의 요소에도 color 적용
        ['path', 'circle', 'rect', 'polygon', 'ellipse'].forEach(selector => {
          svgElement.querySelectorAll(`${selector}:not([fill])`).forEach(el => {
            el.setAttribute('fill', color);
          });
        });

        // 스타일 적용
        const finalSvg = svgElement.outerHTML.replace('<svg', 
          `<svg style="${convertStyleToString(svgStyle)}"`);
        
        setSvgContent(finalSvg);
      }
    };

    loadSvg();
  }, [svgPath, svgStyle, color]); // color를 의존성 배열에 추가

  const convertStyleToString = (styleObject) => {
    if (!styleObject) return '';
    return Object.entries(styleObject)
      .map(([key, value]) => {
        const cssKey = key.replace(/([A-Z])/g, '-$1').toLowerCase();
        return `${cssKey}:${value}`;
      })
      .join(';');
  };

  return <span className={className} style={style} dangerouslySetInnerHTML={{ __html: svgContent }} />;
};

export default SVGIcon;
import React, { createContext, useState, useContext, useEffect } from 'react'
import { colors } from 'settings/settings'
const SpotContext = createContext();



const places = [
  { id: 1, name: '표지판: 어서오십시오', category: '1 경계 표지(판)', color: 'blue' },
  { id: 2, name: '표지판: 서울과 경기 표지판이 8차로', category: '1 경계 표지(판)', color: 'blue' },
  { id: 3, name: '터널 속', category: '9 터널', color: 'green' },
  { id: 4, name: '하천', category: '3 하천', color: 'orange' },
  { id: 5, name: '도로', category: '4 도로', color: 'purple' },
  { id: 6, name: '다리 (하오정교)', category: '7 다리', color: 'yellow' },
  { id: 7, name: '사거리', category: '8 사거리', color: 'pink' },
  { id: 8, name: '사거리', category: '8 사거리', color: 'pink' },
];

const _categories = ['All', '1 경계 표지(판)',
  '2 상징물',
  '3 하천',
  '4 도로',
  '5 회전차로',
  '6 도로 노면 표시',
  '7 다리',
  '8 사거리',
  '9 터널',
  '10 지하철역',
  '11 공원',
  '12 골프장',
  '13 세차장',
  '14 학교',
  '15 아파트: 단지',
  '16 아파트: 동',
  '17 아파트: 놀이터',
  '18 건물',
  '19 산: 등산로',
  '20 산: 정상석',
  '21 산: 바위',
  '22 산: 헬리콥터 승강장',
  '23 성벽',
  '24 원형 트랙',
  '25 수상콜택시'
];

const getListData = async (url, callback = () => {}) => {
  let page = 1;
  let allPosts = [];
  
  try {
    while (true) {
      const pageUrl = `${url}&page=${page}`;
      const response = await fetch(pageUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        // 400이나 404 에러가 나면 데이터가 더 이상 없는 것으로 간주하고 종료
        break;
      }

      const data = await response.json();
      if (!data || data.length === 0) break;
      
      allPosts = [...allPosts, ...data];
      page++;
    }

    // console.log(allPosts);
    callback(allPosts);
    return allPosts;
    
  } catch (error) {
    console.error('Fetch error:', error);
    return null;
  }
};

const getCategories = async (callback=()=>{}) => {
  try {
    const response = await fetch('https://amateur-seoul.com/wp-json/wp/v2/location_category?per_page=100', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json(); // JSON 데이터를 가져옴
    callback(data);
  } catch (error) {
    console.error('Fetch error:', error);
    return null; // 오류 발생 시 null 반환
  }
}


export const SpotProvider = ({children}) => {
  const [spots, setSpots] = useState([]);
  const [scape, setScape] = useState([]);
  const [currentSpots, setCurrentSpots] = useState([]);
  const [ selectedSpot, setSelectedSpot ] = useState(null);

  const [loading, setLoading] = useState(true); // 로딩 상태 추가
  const [listData, setListData] = useState([]);
  const [categoriesData, setCategoriesData] = useState(null);
  const [categories, setCategories] = useState(null);

  const [elapsedTime, setElapsedTime] = useState(0.980668);

  // useEffect(()=>{
  //   if(spots.length>0){
  //     console.log("spots",spots);
  //   }
  // },[spots]);

  useEffect(()=>{
    if(spots.length>0){
      setCurrentSpots(spots);
    }
  },[spots]);

  useEffect(()=>{
    if(spots.length>0 && scape.length>0){
      setLoading(false);
    }
  },[spots,scape]);

  const resetCurrentSpots = () => {
    setCurrentSpots(spots);
  }

  

  useEffect(()=>{
    getCategories((data)=>{
      setCategoriesData(data.map(item=>{return {id:item.id,name:item.name,name_en:item.acf.title_en,color:item.acf.color}}));
    });
  },[]);

  useEffect(()=>{
    if(categoriesData){
      const _categories = categoriesData.map(item=>item.name);
      _categories.unshift("All");
      setCategories(_categories);
      getListData('https://amateur-seoul.com/wp-json/wp/v2/spots?_fields=id,title,title_en,location_category,thumbnail_url,location,highlight&per_page=100',(data)=>{
        setListData(data.map(item=>{
          let categoryName;
          let categoryNameEn;
          let category;
          let color;
          if(item.location_category.length>0){
            category = categoriesData.find(c=>c.id === item.location_category[0]);
            categoryName = category.name;
            categoryNameEn = category.name_en;
            color = category.color //colors.find(c=>c.id === category.id).color;
          }
          return {id:item.id,name:item.title.rendered, name_en:item.title_en, category:categoryName, category_en:categoryNameEn, color, thumbnail_url:item.thumbnail_url}
        }));

        setSpots(data.filter(item=>item.location).map(item=>{
          const result = {id:item.id,title:item.title.rendered,title_en:item.title_en};
          const location = item.location;
          const highlight = item.highlight;
          if(item.location_category.length>0){
            const category = categoriesData.find(c=>c.id === item.location_category[0]);
            const categoryName = category.name;
            const categoryNameEn = category.name_en;
            result.color = colors.find(c=>c.id === category.id).color;
            result.category = categoryName;
            result.category_en = categoryNameEn
          }
          if(location){
            const location_data = location.split(",");
            result.lat = parseFloat(location_data[0]);
            result.lng = parseFloat(location_data[1]);
          }
          if(highlight) result.highlight = highlight;
          return result;
        }));
      });

      getListData('https://amateur-seoul.com/wp-json/wp/v2/scape?_fields=id,title,title_en,location&per_page=100',(data)=>{

        setScape(data.filter(item=>item.location).map(item=>{
          const result = {id:item.id,title:item.title.rendered,title_en:item.title_en};
          const location = item.location;
          if(location){
            const location_data = location.split(",");
            result.lat = parseFloat(location_data[0]);
            result.lng = parseFloat(location_data[1]);
          }
          return result;
        }));
      });
    }
  },[categoriesData]);

  return (
    <SpotContext.Provider value={{spots, setSpots,currentSpots,setCurrentSpots, resetCurrentSpots, categories, categoriesData, listData ,loading, selectedSpot, setSelectedSpot, scape, elapsedTime, setElapsedTime}}>
      {children}
    </SpotContext.Provider>
  )
}

export const useSpot = () => useContext(SpotContext);
import React, { useState,useEffect } from 'react';

const Gallery = ({ images, className }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(()=>{
    if(images.length>0)
    setSelectedImage(images[0]);
  },[images]);

  const handleThumbnailClick = (image) => {
    setSelectedImage(image);
  };

  const handleImageClick = (image) => {
    setSelectedImage(null);
  };

  return (
    <div className={className} style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      {/* 상단의 큰 이미지 영역 */}
      {selectedImage && (
        <div onClick={handleImageClick} style={{ width: '100%' }}>
          <img src={selectedImage.image} alt="Selected" style={{ width: '100%', height: 'auto' }} />
          <p className='mt-2'>{selectedImage.caption}</p>
        </div>
      )}
      
     {/* 5열 그리드 썸네일 */}
     <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        gap: '1rem',
      }}>
        {images.map((image, index) => (
          <div
            key={index}
            style={{
              position: 'relative',
              cursor: 'pointer',
              width: '100%',
              aspectRatio: '1 / 1',
            }}
            onClick={() => handleThumbnailClick(image)}
          >
            {/* 썸네일 이미지 */}
            <img
              src={image.thumbnail}
              alt={`Thumbnail ${index}`}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                filter: selectedImage === image ? 'brightness(0.4)' : 'none', // 선택된 썸네일 어둡게 표시
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
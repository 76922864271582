import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useProgress } from 'context/ProgressContext';
import { useSpot } from 'context/SpotContext'
import seoul_shape from 'assets/data/converted_data.json'
import bounds from 'assets/data/bounds.json'


function getAverage(arr) {
  const sum = arr.reduce((acc, val) => acc + val, 0); // 배열의 합을 구함
  const average = sum / arr.length; // 합을 배열의 길이로 나눔
  return average;
}

function vectorAngle(vector) {
  // 벡터의 x, y 값을 기준으로 각도 구하기 (라디안 단위)
  const angleRadians = Math.atan2(vector.y, vector.x);

  // 각도를 도(degree) 단위로 변환
  const angleDegrees = angleRadians * (180 / Math.PI);
  // console.log("angleDegrees",angleDegrees)
  return angleDegrees;
}

function adjustAngle(angle) {
  // 각도가 -180도에서 180도 범위를 넘지 않도록 보정
  while (angle > 180) angle -= 360;
  while (angle < -180) angle += 360;
  return angle;
}

// 경계값을 구하는 함수
function getBounds(coordinates) {
  let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;

  coordinates.forEach(([lon, lat]) => {
    if (lon < minX) minX = lon;
    if (lat < minY) minY = lat;
    if (lon > maxX) maxX = lon;
    if (lat > maxY) maxY = lat;
  });

  // console.log({ minX, minY, maxX, maxY });

  return { minX, minY, maxX, maxY };
}

function convertCoordinatesToSvgPath(coordinates, viewBoxWidth = 1000) {
  const { minX, minY, maxX, maxY } = getBounds(coordinates);
  // console.log("bound",{ minX, minY, maxX, maxY });
  const viewBoxHeight = viewBoxWidth * (maxY - minY)/(maxX - minX);

  // 좌표를 SVG에 맞게 스케일링
  const scaleX = viewBoxWidth / (maxX - minX);
  const scaleY = viewBoxHeight / (maxY - minY);
  const scale = Math.min(scaleX, scaleY);
  

  // 오프셋 계산
  const offsetX = -minX * scale;
  const offsetY = maxY * scale;

  // SVG 경로 생성
  return coordinates.map(([lon, lat]) => {
    const x = (lon * scale) + offsetX;
    const y = (lat * -scale) + offsetY;
    return `${x},${y}`;
  }).join(" ");
}


// 두 점 사이의 거리를 계산하는 함수 추가
function calculateDistance(x1, y1, x2, y2) {
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
}

// 위도/경도를 SVG 좌표로 변환하는 함수
function convertLatLngToSvgCoords(lat, lng, bounds, viewBoxWidth, viewBoxHeight) {
  const { minX, minY, maxX, maxY } = bounds;
  const x = ((lng - minX) / (maxX - minX)) * viewBoxWidth;
  const y = ((maxY - lat) / (maxY - minY)) * viewBoxHeight;
  return { x, y };
}

function generateSampledPoints(path, numSamples, bounds, viewBoxWidth, viewBoxHeight) {
  if (!path) return [];
  
  const length = path.getTotalLength();
  const step = length / numSamples;
  const sampledPoints = [];
  
  for (let i = 0; i <= numSamples; i++) {
    const distance = i * step;
    const point = path.getPointAtLength(distance);
    
    // SVG 좌표를 위도/경도로 역변환 (필요한 경우)
    const { minX, minY, maxX, maxY } = bounds;
    const lng = minX + (point.x / viewBoxWidth) * (maxX - minX);
    const lat = maxY - (point.y / viewBoxHeight) * (maxY - minY);
    
    sampledPoints.push({
      x: point.x,
      y: point.y,
      distance,
      elapsedTime: distance / length,
      lat,
      lng
    });
  }
  
  return sampledPoints;
}

const MovingDotOnPath = () => {
  const { isKorean, setContentOpened } = useProgress();
  const { spots, scape, setSelectedSpot, currentSpots, elapsedTime, setElapsedTime } = useSpot();

  const containerRef = useRef(null);
  const svgRef = useRef(null);
  const dotRef = useRef(null);
  const dotsRef = useRef([]);
  const textsRef = useRef([]);
  const frameRef = useRef(null);
  const duration = 1; // 5초 동안 이동
  const pointRange = 2;
  const [value, setValue] = useState();
  // const [elapsedTime, setElapsedTime] = useState(0.980668);
  const [zoom, setZoom] = useState(10);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [viewBoxHeight, setViewBoxHeight] = useState(1000);
  const [sampledPoints, setSampledPoints] = useState([]);
  const [shape,setShape] = useState(null);
  const [isInit, setIsInit] = useState(false);

  const sensitivity = 0.0000005;
  const touchSpeed = 3;

  useEffect(()=>{
    if(!shape){
      const { minX, minY, maxX, maxY } = getBounds(seoul_shape);
      const viewBoxHeight = 1000 * (maxY - minY)/(maxX - minX);
      setViewBoxHeight(viewBoxHeight);
      const _shape = `M ${convertCoordinatesToSvgPath(seoul_shape)} Z`;
      setShape(_shape);
      // console.log("_shape",_shape);
    }
  },[]);

  useEffect(() => {
    if (svgRef.current && shape && isInit && sampledPoints.length===0) {
      const path = svgRef.current.querySelector('path');
      if (path) {
        const points = generateSampledPoints(path, 2000, bounds, 1000, viewBoxHeight);
        setSampledPoints(points);
      }
    }
  }, [shape, viewBoxHeight,isInit]);

  useEffect(()=>{
    if(shape && svgRef && spots.length>0  && scape.length > 0 && !isInit){
      handleAfterInjection(svgRef.current);
      setIsInit(true);
      // console.log("handleAfterInjection");
    }
  },[shape,spots,scape]);


  const findClosestPointFromSampled = useCallback((targetLat, targetLng) => {
    if (sampledPoints.length === 0) return null;
    
    const targetPoint = convertLatLngToSvgCoords(
      targetLat,
      targetLng,
      bounds,
      1000,
      viewBoxHeight
    );
    
    let closestPoint = sampledPoints[0];
    let closestDistance = calculateDistance(
      targetPoint.x,
      targetPoint.y,
      closestPoint.x,
      closestPoint.y
    );
    
    for (const point of sampledPoints) {
      const distance = calculateDistance(
        targetPoint.x,
        targetPoint.y,
        point.x,
        point.y
      );
      
      if (distance < closestDistance) {
        closestDistance = distance;
        closestPoint = point;
      }
    }
    
    return closestPoint.elapsedTime * duration;
  }, [sampledPoints, viewBoxHeight, duration]);
  
  // 위도/경도로 이동하는 함수
  const moveToLatLng = useCallback((lat, lng) => {
    const newElapsedTime = findClosestPointFromSampled(lat, lng);
    if (newElapsedTime !== null) {
      setElapsedTime(newElapsedTime);
    }
  }, [findClosestPointFromSampled]);

  useEffect(()=>{
    if(currentSpots.length === 1){
      const spot = currentSpots[0];
      moveToLatLng(spot.lat, spot.lng);
    }
  },[currentSpots])




  useEffect(()=>{ 
    const diagonal = Math.sqrt(Math.pow(window.innerWidth,2)+Math.pow(window.innerHeight,2));
    const _zoom = 50000/window.innerWidth//100000/diagonal;
    setZoom(_zoom);
    const svgElement = svgRef.current;
    if (svgElement) {
      moveDotOnPath(svgElement);
    }
  },[windowSize])

  // useEffect(()=>{ 
    // console.log("zoom",zoom);
  // },[zoom])


  const handleWheel = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setElapsedTime((prevTime) => {
      const delta = event.deltaY * sensitivity; // 스크롤 감도 조정
      let newElapsedTime = prevTime + delta;
      if (newElapsedTime < 0) newElapsedTime = duration + newElapsedTime;
      if (newElapsedTime > duration) newElapsedTime = newElapsedTime - duration;
      return newElapsedTime;
    });
  };

 // Use refs to keep track of touch variables without causing re-renders
 const touchStartY = useRef(0);
 const touchLastY = useRef(0);
 const touchStartTime = useRef(0);
 const touchLastTime = useRef(0);
 const touchVelocityY = useRef(0);
 const inertiaAnimationFrame = useRef(null);

 const handleTouchStart = (event) => {
   touchStartY.current = event.touches[0].clientY;
   touchLastY.current = touchStartY.current;
   touchStartTime.current = event.timeStamp;
   touchLastTime.current = touchStartTime.current;
   touchVelocityY.current = 0;

   // Stop any ongoing inertia animation
   if (inertiaAnimationFrame.current) {
     cancelAnimationFrame(inertiaAnimationFrame.current);
     inertiaAnimationFrame.current = null;
   }
 };

 const handleTouchMove = (event) => {
   event.preventDefault();

   const touchCurrentY = event.touches[0].clientY;
   const currentTime = event.timeStamp;
   const deltaY = touchLastY.current - touchCurrentY;
   const deltaTime = currentTime - touchLastTime.current || 16; // Prevent division by zero

   // Calculate velocity (pixels per millisecond)
   touchVelocityY.current = deltaY / deltaTime;

   touchLastY.current = touchCurrentY;
   touchLastTime.current = currentTime;

   setElapsedTime((prevTime) => {
     const delta = deltaY * sensitivity * touchSpeed; // Adjust sensitivity as needed
     let newElapsedTime = prevTime + delta;
     if (newElapsedTime < 0) newElapsedTime += duration;
     if (newElapsedTime > duration) newElapsedTime -= duration;
     return newElapsedTime;
   });
 };

 const handleTouchEnd = () => {
   const deceleration = 0.002; // Adjust deceleration rate (higher value decelerates faster)
   const initialVelocity = touchVelocityY.current;
   const startTime = performance.now();

   const inertiaStep = (currentTime) => {
     const elapsed = currentTime - startTime;
     const velocity = initialVelocity - deceleration * elapsed * Math.sign(initialVelocity);

     // Stop the inertia animation if velocity changes direction or is near zero
     if (Math.sign(initialVelocity) !== Math.sign(velocity) || Math.abs(velocity) < 0.01) {
       touchVelocityY.current = 0;
       return;
     }

     const delta = velocity * 16; // Assuming 60 FPS, so approximately 16ms per frame

     setElapsedTime((prevTime) => {
       let newElapsedTime = prevTime + delta * sensitivity * touchSpeed;
       if (newElapsedTime < 0) newElapsedTime += duration;
       if (newElapsedTime > duration) newElapsedTime -= duration;
       return newElapsedTime;
     });

     inertiaAnimationFrame.current = requestAnimationFrame(inertiaStep);
   };

   inertiaAnimationFrame.current = requestAnimationFrame(inertiaStep);
 };

 const handleWindowResized = useCallback(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
 },[svgRef,elapsedTime]);

  const preventDefaultBehavior = (event) => {
    event.preventDefault(); // 터치 이벤트로 인한 기본 동작 방지
  };

  useEffect(() => {
    if (containerRef.current) {
      // 마우스 휠 이벤트 리스너 추가
      containerRef.current.addEventListener('wheel', handleWheel, { passive: false });
      // 모바일 터치 이벤트 리스너 추가
      containerRef.current.addEventListener('touchstart', handleTouchStart, { passive: false });
      containerRef.current.addEventListener('touchmove', handleTouchMove, { passive: false });
      containerRef.current.addEventListener('touchend', handleTouchEnd);
      containerRef.current.addEventListener('touchcancel', handleTouchEnd);
      window.addEventListener('resize', handleWindowResized);
    
      return () => {
        if (containerRef.current) {
          // 이벤트 리스너가 제거되기 전에 containerRef.current가 유효한지 확인
          containerRef.current.removeEventListener('wheel', handleWheel);
          containerRef.current.removeEventListener('touchstart', handleTouchStart);
          containerRef.current.removeEventListener('touchmove', handleTouchMove);
          containerRef.current.removeEventListener('touchend', handleTouchEnd);
          containerRef.current.removeEventListener('touchcancel', handleTouchEnd);
          window.removeEventListener('resize', handleWindowResized);
        }
      };
    }
  }, [containerRef]);


  useEffect(() => {
    const svgElement = svgRef.current;
    // console.log("elapsedTime",elapsedTime);
    if (svgElement) {
      moveDotOnPath(svgElement);
    }
  }, [elapsedTime]); // elapsedTime이 변경될 때마다 점의 위치를 업데이트


  const moveDotOnPath = useCallback((svgElement) => {
    const path = svgElement.querySelector('path');
    if (path) {
      const length = path.getTotalLength();
      const viewBox = svgElement.viewBox.baseVal; // 원래 뷰포트 크기
      const svgRect = svgElement.getBoundingClientRect(); // 실제 렌더링된 크기
      const frameRect = frameRef.current.getBoundingClientRect();
      const scaleX = frameRect.width / viewBox.width;
      const scaleY = frameRect.height / viewBoxHeight;
  
      // console.log(frameRect.width,frameRect.height,viewBox.width,viewBoxHeight);
      // 애니메이션 시작
      const startTime = performance.now();

      const distance = (elapsedTime / duration) * length;

      if (distance < length) {
        const point = path.getPointAtLength(distance);

        // SVG 중심점 계산
        const centerX = frameRect.width / 2;
        const centerY = frameRect.height / 2;

        // // 점의 위치를 중심점으로 이동시키기 위해 좌표 조정
        // const x = (point.x * scaleX) - centerX;
        // const y = (point.y * scaleY) - centerY;

        // // path.style.transform = `translate(${x}px, ${y}px)`;

        // console.log(svgRect);
        // 이미지 스케일에 맞게 좌표 조정
        const scaledX = (point.x) *scaleX - centerX;
        const scaledY = (point.y) *scaleY - centerY;
        
        dotRef.current.style.transform = `translate(${point.x}px, ${point.y}px)`;

        let angles = 0;
        let count = 0;
        let diff = 0.001*length;
        let range = pointRange;
        let angleArr =[];
        const limitValue = (r,max)=>{
          if(r>max) return r-max;
          else if(r<0) return max+r;
          else return r;
        };
        let points = [];
        for (let i = -range; i < range; i++) {
          
          const r1 = limitValue(distance + i * diff, length);
          const r2 = limitValue(distance + (i + 1) * diff, length);
          const v1 = path.getPointAtLength(r1);
          const v2 = path.getPointAtLength(r2);

          if(i==-range)points.push(v1,v2);
          else points.push(v2);
          
          // console.log(r1,r2,v1,v2);
          let angle = vectorAngle({ x: v2.x - v1.x, y: v2.y - v1.y });
          // 이전 각도와 비교하여 보정
          if (i > -range) {
            const prevAngle = vectorAngle({ x: path.getPointAtLength(distance - (i - 1) * diff).x - v1.x, y: path.getPointAtLength(distance - (i - 1) * diff).y - v1.y });
            angle = adjustAngle(angle - prevAngle) + prevAngle; // 각도 보정
          }

          angleArr.push(angle);
        }

        let rotateAngle = 0;

        
        if(angleArr.length>1){
          for(let i=1; i<angleArr.length; i++){
            let prevAngle = angleArr[i-1];
            let angle = angleArr[i];
            angleArr[i] = adjustAngle(angle - prevAngle) + prevAngle;
            prevAngle = angleArr[i];
          }
          rotateAngle = getAverage(angleArr);
          // console.log("process");
        }
        

        if(dotsRef.current){
          for(let i=0; i<points.length; i++){
            const pt = points[i];
            dotsRef.current[i].style.transform = `translate(${pt.x}px, ${pt.y}px)`;
          }
        }

        if(textsRef.current){
          for(let i=0; i<textsRef.current.length; i++){
            const text = textsRef.current[i];
            const theta = (rotateAngle+180)/180 * Math.PI;
            const dist = 0.35;
            const mx = Math.cos(theta) * dist;
            const my = Math.sin(theta) * dist;
            const px = text.position.x+mx;
            const py = text.position.y+my;
            // console.log("text",text.position)
            text.setAttribute("x", px); // x 좌표
            text.setAttribute("y", py); // x 좌표
            text.setAttribute("transform", `rotate(${rotateAngle+270}, ${px}, ${py})`);
            if(text.category){
              const category = text.category;
              const dist = 0.7;
              const mx = Math.cos(theta) * dist;
              const my = Math.sin(theta) * dist;
              const px = text.position.x+mx;
              const py = text.position.y+my;
              category.setAttribute("x", px); // x 좌표
              category.setAttribute("y", py); // x 좌표
              category.setAttribute("transform", `rotate(${rotateAngle+270}, ${px}, ${py})`);
        
            }
            // text.style.transform = `translate(${text.position.x+mx}px, ${text.position.y+my}px) rotate(${rotateAngle-90}deg)`;
          }
        }

        // console.log("angles",rotateAngle,angleArr)//rotate(${angles}deg) 
        // frameRef.current.style.transform = `rotate(${-rotateAngle+90}deg)`
        // console.log("zoom apply",zoom);
        svgRef.current.style.transform = `scale(${zoom}) rotate(${-rotateAngle+90}deg) translate(${-scaledX}px, ${-scaledY}px)  `; // scale(${zoom}) rotate(${-rotateAngle+90}deg)

      }

    }
  },[viewBoxHeight,elapsedTime,zoom]);

  const handleAfterInjection = (svg) => {

    // console.log('SVG successfully loaded'); // SVG가 제대로 로드되었는지 확인
  
    const path = svg.querySelector('path');
    if (!path) {
      // console.error('Path element not found in SVG');
      return;
    }
  

    let unit = 0.05;
    // console.log('Path found:'); // Path 요소가 있는지 확인
    svgRef.current = svg;

    path.style.stroke = '#fff';
    path.style.strokeWidth = unit;

  // 새로운 <circle> 요소를 생성하여 SVG에 추가
    const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
    circle.setAttribute('r', unit*3);
    circle.setAttribute('fill', 'black');
    circle.setAttribute('stroke', 'white');
    circle.setAttribute('stroke-width', unit*1.5);
    circle.setAttribute('cx', '0');
    circle.setAttribute('cy', '0');
    dotRef.current = circle;

    // SVG에 circle 추가 (마지막에 추가하거나 원하는 위치에 삽입 가능)
    svg.appendChild(circle);
    for(let i=0; i<(pointRange*2+1); i++){
      const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
      circle.setAttribute('r', unit*5);
      circle.setAttribute('fill', 'blue');
      circle.setAttribute('cx', '0');
      circle.setAttribute('cy', '0');
      circle.style.display ="none";
      dotsRef.current[i] = circle;
      svg.appendChild(circle);
    }


    for(let spot of spots){
      const { minX, minY, maxX, maxY } = bounds;
      
      const x = ((spot.lng - minX)/(maxX - minX)) * 1000;
      const y = (1-(spot.lat - minY)/(maxY - minY)) * 651.4444627510896;

      const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
      circle.setAttribute('r', unit*3.5);

      circle.setAttribute('fill', spot.highlight?'#e6ff00':'white');
      circle.setAttribute('stroke', 'transparent');
      circle.setAttribute('cx',x);
      circle.setAttribute('cy',y);  
      // SVG에 circle 추가 (마지막에 추가하거나 원하는 위치에 삽입 가능)
      svg.appendChild(circle);
      
      // console.log(minX, minY, maxX, maxY);
      // console.log("x,y",x,y);

      const categoryText = document.createElementNS("http://www.w3.org/2000/svg", "text");
      categoryText.setAttribute("x", 0); // 같은 x 위치 유지
      categoryText.setAttribute("y", 0); // 같은 x 위치 유지
      categoryText.setAttribute("text-anchor", "middle"); // 가운데 정렬
      // categoryText.setAttribute("dy", `${-unit*6}px`); // 아래쪽에 위치하도록 dy 설정
      categoryText.setAttribute('fill', spot.highlight?'#e6ff00':'white')
      categoryText.setAttribute("font-size", unit*4); // 작은 폰트 크기
      categoryText.setAttribute("stroke", "black");
      categoryText.setAttribute("stroke-width", unit); 
      categoryText.setAttribute("stroke-linejoin", "round");
      categoryText.setAttribute("paint-order", "stroke");
      categoryText.textContent = isKorean?spot.category:spot.category_en;

      const textElement = document.createElementNS("http://www.w3.org/2000/svg", "text");
      textElement.setAttribute("x", x); // x 좌표
      textElement.setAttribute("y", y); // y 좌표
      textElement.setAttribute("text-anchor", "middle"); // 가운데 정렬
      textElement.setAttribute('fill', spot.highlight?'#e6ff00':'white')
      textElement.setAttribute("font-size", unit*5); // 큰 폰트 크기
      textElement.setAttribute("stroke", "black");
      textElement.setAttribute("stroke-width", unit); 
      textElement.setAttribute("stroke-linejoin", "round");
      textElement.setAttribute("paint-order", "stroke");
      textElement.textContent = isKorean?spot.title:spot.title_en;
      textElement.category = categoryText;
      // textElement.appendChild(smallText);
      // textElement.appendChild(largeText);

      const onClick = () => {
        setContentOpened(true);
        setSelectedSpot(spot);
      }

      [circle,categoryText,textElement].forEach((elem)=>{
        elem.onclick = onClick;
        elem.style.cursor = "pointer";
      });

      
      
      textElement.position = {x,y}
      textsRef.current.push(textElement);
      svg.appendChild(textElement);
      svg.appendChild(categoryText);
    }

    for(let spot of scape){
      const { minX, minY, maxX, maxY } = bounds;
      
      const x = ((spot.lng - minX)/(maxX - minX)) * 1000;
      const y = (1-(spot.lat - minY)/(maxY - minY)) * 651.4444627510896;

      const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
      circle.setAttribute('r', unit*2.5);
      circle.setAttribute('fill', 'black');
      circle.setAttribute('stroke', 'white');
      circle.setAttribute('stroke-width', unit);
      circle.setAttribute('cx',x);
      circle.setAttribute('cy',y);  
      // SVG에 circle 추가 (마지막에 추가하거나 원하는 위치에 삽입 가능)
      svg.appendChild(circle);
      
      // console.log(minX, minY, maxX, maxY);
      // console.log("x,y",x,y);

      const textElement = document.createElementNS("http://www.w3.org/2000/svg", "text");
      textElement.setAttribute("x", x); // x 좌표
      textElement.setAttribute("y", y); // y 좌표
      textElement.setAttribute("font-size", unit*5); // 폰트 크기
      textElement.setAttribute("text-anchor", "middle"); // 가운데 정렬
      textElement.setAttribute("fill", "white"); // 텍스트 색상
      textElement.setAttribute("stroke", "black");
      textElement.setAttribute("stroke-width", unit); 
      textElement.setAttribute("stroke-linejoin", "round");
      textElement.setAttribute("paint-order", "stroke");
      textElement.textContent = isKorean?spot.title:spot.title_en;
      
      textElement.position = {x,y}
      textsRef.current.push(textElement);
      svg.appendChild(textElement);
    }
    moveDotOnPath(svg);
  };

  return (
  <>
    <div ref={containerRef} style={{ position: 'relative', width:"100%", height:"100%", display:'flex',alignItems:"center",justifyContent:"center"/* ,  touchAction: 'none', overscrollBehavior: 'none' */ }}>
      {shape&&<div ref={frameRef} style={{position:'relative',width:"100%",height:"auto",transformOrigin:'center center'}}><svg overflow="visible" style={{width:"100%",height:"auto"}} /* style={{transformOrigin:'center center'}} */ viewBox={`0 0 1000 ${viewBoxHeight}`} ref={svgRef}>
        <path d={shape} fill="none" stroke="white" />
      </svg></div>}
      
    </div>
    <div className='absolute text-white left-0 top-0'>{value}</div>
    </>
  );
};

export default MovingDotOnPath;
import React, { useState } from 'react';

const ToggleButton = ({ onToggle, labelOn, labelOff, IconOn, IconOff, className }) => {
  const [isToggled, setIsToggled] = useState(false);

  const toggle = () => {
    const newToggledState = !isToggled;
    setIsToggled(newToggledState);
    
    // 외부에서 전달된 콜백 함수 호출
    if (onToggle) {
      onToggle(newToggledState);
    }
  };

  return (
    <button
      onClick={toggle}
      className={`px-4 py-2 focus:outline-none transition-all duration-300 ${className}`}
    >
      <div className="flex items-center justify-center">
        {isToggled ? (
          IconOn ? <IconOn className={(labelOn||labelOff)&&`mr-2`} /> : null
        ) : (
          IconOff ? <IconOff className={(labelOn||labelOff)&&`mr-2`} /> : null
        )}
        {(labelOn||labelOff)&&<span>{isToggled ? labelOn : labelOff}</span>}
      </div>
    </button>
  );
};

export default ToggleButton;
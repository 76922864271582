import React from 'react';
import { useProgress } from 'context/ProgressContext';
import SVGIcon from 'components/ui/SVGIcon';
import url_icon_map from 'assets/images/icon-map.svg';
import url_icon_boundary from 'assets/images/icon-boundary.svg';

const MainMenu = ({ children }) => {
  const { section, setSection,setListOpened, isKorean } = useProgress();

  const moveSection = (index) => {
    setSection(index);
    setListOpened(false);
  };

  return (
    <div className="absolute flex inset-x-0 bottom-0 h-18">
      {children}
      <button
        onClick={() => moveSection(0)}
        className={`m-4 mr-2 border-2 border-black flex flex-row items-center justify-center flex-1 py-2 focus:outline-none transition-colors bg-black text-white ${
          section==0?'border-black':'border-white'
        } active:bg-point-yellow active:text-black`}
      >
        <SVGIcon svgPath={url_icon_map} color={'white'} className="inline-block mr-2" style={{width:"15px",height:"15px"}} />
        <span className="text-md">{isKorean?`지도 보기`:`View Map`}</span>
      </button>
      <button
        onClick={() => moveSection(1)}
        className={`m-4 ml-2 border-2 border-black flex flex-row items-center justify-center flex-1 py-2 focus:outline-none transition-colors bg-white text-black ${
          section==0?'border-black':'white'
        } active:bg-point-yellow active:text-black`}
      >
        <SVGIcon svgPath={url_icon_boundary} color={"black"} className="inline-block mr-2" style={{width:"15px",height:"15px"}} />
        <span className="text-md">{isKorean?`경계 걷기`:`Boundary Walking`}</span>
      </button>
    </div>
  );
};

export default MainMenu;
import React, { createContext, useState, useContext } from 'react'

const ProgressContext = createContext();

export const ProgressProvider = ({children}) => {
  const [section, setSection] = useState(0);
  const [listOpened, setListOpened] = useState(false);
  const [contentOpened, setContentOpened] = useState(false);
  const [infoOpened, setInfoOpened] = useState(false);
  const [isKorean, setIsKorean] = useState(true);
  
  return (
    <ProgressContext.Provider value={{section,setSection,listOpened, setListOpened, contentOpened, setContentOpened, infoOpened, setInfoOpened ,isKorean,setIsKorean}}>
      {children}
    </ProgressContext.Provider>
  )
}

export const useProgress = () => useContext(ProgressContext);
import React, { useState } from 'react';
import { colors } from 'settings/settings'
import { BsFilterLeft, BsChevronDown, BsChevronRight } from "react-icons/bs";
import { FaSpinner } from 'react-icons/fa';
import { useProgress } from 'context/ProgressContext';
import { useSpot } from 'context/SpotContext';
import SVGIcon from 'components/ui/SVGIcon';
import url_icon_close from 'assets/images/icon-close.svg';


const AccordionCategory = ({ category, places = [], currentSpots, onPlaceSelect }) => {
  const { section, isKorean } = useProgress();
  const [isOpen, setIsOpen] = useState(false);
  const categoryColor = category.color || '#gray-500';
  const hasPlaces = places.length > 0;

  return (
    <div className={`border-b border-inherit ${section===0?'border-black':'border-white'}`}>
      <button
        className={`w-full px-4 py-3 flex items-center justify-between ${
          hasPlaces ? 'active:bg-point-yellow active:text-black' : 'opacity-50 cursor-not-allowed'
        }`}
        onClick={() => hasPlaces && setIsOpen(!isOpen)}
      >
        <div className="flex items-center">
          {hasPlaces ? (
            isOpen ? <BsChevronDown className="mr-2" /> : <BsChevronRight className="mr-2" />
          ) : (
            <div className="w-4 mr-2" /> // 빈 공간으로 정렬 유지
          )}
          <div 
            className="w-3 h-3 mr-3 rounded-full" 
            style={{ background: categoryColor }}
          />
          <span className="text-md">{isKorean?category.name:category.name_en}</span>
        </div>
        <span className="ml-2 text-xs text-gray-500 float-right">{places.length}</span>
      </button>
      {isOpen && hasPlaces && (
        <ul className="border-inherit">
          {places.map((place) => (
            <li
              key={place.id}
              className={`cursor-pointer ${section===0?'border-black':'border-white'} active:bg-point-yellow active:text-black ${
                currentSpots.length === 1 && place.id === currentSpots[0].id
                  ? 'bg-point-yellow text-black'
                  : 'hover:bg-point-yellow hover:text-black'
              }`}
              onClick={() => onPlaceSelect(place.id)}
            >
              <div className={`flex items-center pl-10 py-2 border-t ${section===0?'border-black':'border-white'}`}>
                <div className="flex-1">
                  <p className="text-md">{isKorean?place.name:place.name_en}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const PlaceList = () => {
  const { setListOpened, listOpened, section } = useProgress();
  const { spots, currentSpots, setCurrentSpots, listData, loading, categoriesData } = useSpot();

  const selectSpot = (id) => {
    if (spots.find(spot => spot.id === id)) {
      if (currentSpots.length === 1 && currentSpots[0].id === id) {
        setCurrentSpots(spots);
      } else {
        setCurrentSpots([spots.find(spot => spot.id === id)]);
      }
    }
  };

  const closeList = () => {
    setListOpened(false);
  };

  // 카테고리별로 장소 그룹화
  const groupedPlaces = listData.reduce((acc, place) => {
    if (!acc[place.category]) {
      acc[place.category] = [];
    }
    acc[place.category].push(place);
    // 각 카테고리의 장소들을 이름순으로 정렬
    acc[place.category].sort((a, b) => a.name.localeCompare(b.name));
    
    return acc;
  }, {});

  // 모든 카테고리를 colors 배열의 순서대로 표시
  // console.log("categoriesData",categoriesData);
  const allCategories = categoriesData?categoriesData.sort((a,b)=>a.id-b.id):[];

  return (
    <>
      {/* <div onClick={closeList} className={`absolute inset-0 bg-black opacity-20 ${listOpened?"":"invisible"}`} /> */}
      {categoriesData&&<div className={`absolute top-4 left-4 w-70 border-2 flex flex-col ${listOpened?"":"invisible"} ${section===0?'bg-white border-black text-black bottom-36':'bg-black border-white text-white bottom-20'}`}>
        <div className={`flex items-center justify-between p-4 border-b ${section===0?'border-black':'border-white'} h-14`}>
          <button onClick={closeList} className="absolute right-2 top-2 w-10 h-10"><SVGIcon svgPath={url_icon_close}  color={section===0?'black':'white'} className='inline-block w-6 h-6 pd-4' /></button>
        </div>

        {loading ? (
          <div className="flex-1 flex justify-center items-center">
            <FaSpinner className="animate-spin text-gray-500" size={40} />
          </div>
        ) : (
          <div className={`flex-1 overflow-y-auto border-inherit ${section===0?'border-black':'border-white'}`}>
            {allCategories.map(category => (
              <AccordionCategory
                key={category.name}
                category={category}
                places={groupedPlaces[category.name] || []}
                currentSpots={currentSpots}
                onPlaceSelect={selectSpot}
              />
            ))}
          </div>
        )}
      </div>}
    </>
  );
};

export default PlaceList;
import React from 'react';
import MovingDotOnPath from 'components/edge/MovingDotOnPath';
import TouchTest from 'components/edge/TouchTest';


const SectionEdge = ({children}) => {
  return <div className="absolute overflow-hidden inset bottom-0 w-full h-full bg-black">
    <MovingDotOnPath />
    {/* <TouchTest /> */}
    {children}
  </div>
}

export default SectionEdge;
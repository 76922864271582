import React,{ useState } from 'react';
import MainMenu from 'components/MainMenu'
import { useProgress } from 'context/ProgressContext';
import { useSpot } from 'context/SpotContext'
import SectionMap from 'components/section/SectionMap';
import SectionEdge from 'components/section/SectionEdge';
import ToggleButton from 'components/ui/ToggleButton';
import { FaBars, FaTimes } from 'react-icons/fa';
import PlaceList from 'components/list/PlaceListAccordion';
import LoadingScreen from 'components/ui/LoadingScreen';
import SVGIcon from 'components/ui/SVGIcon';
import url_icon_info from 'assets/images/icon-info.svg';
import ContentBox from 'components/content/ContentBox';
import InfoBox from 'components/content/InfoBox';
import { LoadScript } from '@react-google-maps/api';

const MainView = ({children}) => {
  const { section, listOpened, setListOpened,contentOpened, infoOpened, setInfoOpened, isKorean, setIsKorean } = useProgress();
  const { loading } = useSpot();
  const [ loadingComplete, setLoadingComplete ] = useState(false);


  const onListToggle = (isActive)=>{setListOpened(isActive)};
  return <LoadScript googleMapsApiKey="AIzaSyD0-EQPIlTy4USZo5X-n0dM7Pk506v38xk" language={isKorean?'ko':'en'}>
    <div className="absolute inset w-full h-full">
      {children}
      {section==0&&<SectionMap />}
      {section==1&&<SectionEdge />}
      {!listOpened&&<ToggleButton
        onToggle={onListToggle} 
        IconOn={FaTimes}
        IconOff={FaBars}
        labelOn={isKorean?`경계 분류 목록`:`Types of Boundaries`}
        labelOff={isKorean?`경계 분류 목록`:`Types of Boundaries`}
        className={`absolute left-4 top-4 ${listOpened?'invisible':'visible' } ${section===0?"bg-white text-black border-black":"bg-black text-white border-white"} border-2 rounded-none active:bg-point-yellow active:text-black`}
      />}
    
      
      
      <MainMenu />
      {!loadingComplete&&<LoadingScreen fadeOut={!loading} setLoadingComplete={setLoadingComplete} />}
      <div className="absolute right-4 top-4">
        <button onClick={()=>{setIsKorean(!isKorean)}} className={`flex flex-col ${section===0?"bg-white text-black border-black":"bg-black text-white border-white"} border-2 active:bg-point-yellow active:text-black`}>
          <span className={`w-8 h-8 flex items-center justify-center text-sm ${section===0?(isKorean?'bg-black text-white':'bg-white text-black'):(isKorean?'bg-white text-black':'bg-black text-white')}`}>한</span>
          <span className={`w-8 h-8 flex items-center justify-center text-sm border-t-2 ${section===0?"border-black":"border-white"} ${section===0?(!isKorean?'bg-black text-white':'bg-white text-black'):(!isKorean?'bg-white text-black':'bg-black text-white')}`}>EN</span>
        </button>
        <button onClick={()=>{setInfoOpened(!infoOpened)}} className={`mt-4 w-9 h-9 flex items-center justify-center border-2 border-black active:bg-point-yellow active:text-black ${section===0?"bg-white border-black":"bg-black border-white"}`}>
          <SVGIcon svgPath={url_icon_info} color={section===0?"black":"white"} className="inline-block" style={{width:"18px",height:"18px"}} />
        </button>
      </div>
      <PlaceList />
      {contentOpened&&<ContentBox />}
      {infoOpened&&<InfoBox />}
    </div>
  </LoadScript>
}

export default MainView;
import logo from './logo.svg';
import './App.css';
import MainView from 'components/MainView'
import { ProgressProvider } from 'context/ProgressContext';
import { NavermapsProvider } from 'react-naver-maps';
import { SpotProvider } from 'context/SpotContext';
import { LoadScript } from '@react-google-maps/api';
import { Helmet } from 'react-helmet'; // Helmet 추가

function App() {
  return (
    <>
    <Helmet>
      <title>도시의 경계들</title>
      <meta name="description" content="서울을 둘러싼 경계 지점을 함께 여행해볼까요? 이 곳에서 '경계'의 개념은 벽이나 통로가 아니라, 대상이자 목적지가 됩니다. 경계선상에 놓인 하나의 점이 되어 선을 따라 걷고, 선 안팎을 넘나들기도 하며 이전과는 다른 시선과 방식으로 도시를 경험해보세요. " />
    </Helmet>
    <SpotProvider>
      <ProgressProvider>
        {/* <NavermapsProvider 
            ncpClientId='gyuymkbc4s'
            // or finClientId, govClientId  
          > */}
        <MainView />
        {/* </NavermapsProvider> */}
      </ProgressProvider>
    </SpotProvider>
    </>
  );
}

export default App;

import React, { useState, useEffect } from 'react'

import { useProgress } from "context/ProgressContext";
import { useSpot } from "context/SpotContext"
import { FaSpinner } from 'react-icons/fa'; // 로딩 애니메이션 아이콘
import Gallery from 'components/content/Gallery'
import SVGIcon from 'components/ui/SVGIcon';
import url_icon_close from 'assets/images/icon-close.svg';
import HtmlContent from 'components/ui/HtmlContent';

const getContentData = async (url,callback=()=>{}) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json(); // JSON 데이터를 가져옴
    // console.log(data);
    callback(data);
  } catch (error) {
    console.error('Fetch error:', error);
    return null; // 오류 발생 시 null 반환
  }
};

const ContentBox = ({}) => {
  const [contentData, setContentData] = useState(null);
  const { setInfoOpened, section, isKorean } = useProgress();
  const [loading,setLoading] = useState(true);



  useEffect(()=>{
    getContentData(`https://amateur-seoul.com/wp-json/acf/v3/options/options`,(data)=>{
      if(data&& data.acf){
        // console.log(data.acf);
        setContentData(data.acf);
      }
    });
  },[]);

  return <div className={`absolute 
      max-w-md
      inset-x-4
      top-4
      bottom-20
       
      p-10 
      overflow-auto 
      border-2 
      
      md:top-4
      md:right-16
      md:left-auto
      md:bottom-auto
      ${section===0?`bg-white border-black text-black`:`bg-black border-white text-white`}
      
      `}>
          <button onClick={()=>{setInfoOpened(false);}} className="absolute right-2 top-2 w-10 h-10"><SVGIcon color={section===0?'black':'white'} svgPath={url_icon_close} className='inline-block w-6 h-6 pd-4' /></button>

      {contentData?(<>
        <p className='text-md mt-4 info-content'><HtmlContent content={isKorean?contentData.desc_kr:contentData.desc_en} /></p>
      </>)
    :(
      <div className="w-full h-full flex justify-center items-center">
        <FaSpinner className={`animate-spin ${section===0?`text-black`:`text-white`}`} size={40} />
      </div>
    )}
  </div> 
}

export default ContentBox;

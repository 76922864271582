export const colors = [
  { name: '1 경계 표지(판)', color: "#00ff00", id: 8 },
  { name: '2 상징물', color: "#ffff00", id: 9 },
  { name: '3 하천', color: "#0000ff", id: 10 },
  { name: '4 도로', color: "#a5a5a5", id: 11 },
  { name: '5 회전차로', color: "#646464", id: 12 },
  { name: '6 도로 노면 표시', color: "#00ffff", id: 13 },
  { name: '7 다리', color: "#00709b", id: 14 },
  { name: '8 사거리', color: "#ffa1b1", id: 15 },
  { name: '9 터널', color: "#ed00ff", id: 16 },
  { name: '10 지하철역', color: "#7600ff", id: 17 },
  { name: '11 공원', color: "#00af00", id: 18 },
  { name: '12 골프장', color: "#958c00", id: 19 },
  { name: '13 세차장', color: "#0098ff", id: 20 },
  { name: '14 학교', color: "#ffe6a8", id: 21 },
  { name: '15 아파트: 단지', color: "#ffa700", id: 22 },
  { name: '16 아파트: 동', color: "#ffa700", id: 23 },
  { name: '17 아파트: 놀이터', color: "#ffa700", id: 24 },
  { name: '18 건물', color: "#ff0000", id: 25 },
  { name: '19 산: 등산로', color: "#00f0d2", id: 26 },
  { name: '20 산: 정상석', color: "#00f0d2", id: 27 },
  { name: '21 산: 바위', color: "#00f0d2", id: 28 },
  { name: '22 산: 헬리콥터 승강장', color: "#00f0d2", id: 29 },
  { name: '23 성벽', color: "#8d0027", id: 30 },
  { name: '24 원형 트랙', color: "#ffd600", id: 31 },
  { name: '25 수상콜택시', color: "#00009b", id: 32 }
];
import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMap, LoadScript, Marker, Polygon } from '@react-google-maps/api';
import { useSpot } from 'context/SpotContext';
import { useProgress } from 'context/ProgressContext';
import seoul_shape_detail from 'assets/data/converted_data.json';
import SVGIcon from 'components/ui/SVGIcon';
import url_icon_all from 'assets/images/icon-all.svg';
import url_icon_user from 'assets/images/icon-user.svg';


const mapContainerStyle = {
  width: '100%',
  height: '100%'
};


const center = { lat: 37.5665, lng: 126.9780 }; // 서울 시청 위치


const silverStyle = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#c9c9c9"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
];


const Map = ({ children }) => {
  const [map, setMap] = useState(null);
  const { spots, currentSpots,resetCurrentSpots, selectedSpot, setSelectedSpot } = useSpot();
  const { setContentOpened, isKorean } = useProgress();
  
  const [userLocation, setUserLocation] = useState(null);
  const [seoulPath,setSeoulPath] = useState();
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [isUserLocationLoading, setIsUserLocationLoading] = useState(false);
  const [defaultZoom, setDefaultZoom] = useState(null);
  const [zoom, setZoom] = useState(10);


  const drawSeoulOutline = () => {
    return seoul_shape_detail.map(([lng, lat]) => ({ lat, lng }));
  };


  const handleWindowResized = useCallback(() => {
    const _zoom = window.innerWidth>800?11:10;
    setDefaultZoom(_zoom);
 },[]);

 useEffect(()=>{
  if(map && defaultZoom)map.setZoom(defaultZoom);
 },[map,defaultZoom]);

  useEffect(()=>{
    setSeoulPath(drawSeoulOutline());
    handleWindowResized();

    window.addEventListener('resize', handleWindowResized);
    return () => {
      window.removeEventListener('resize', handleWindowResized);
    };
  },[]);


  useEffect(()=>{
    if(map && currentSpots && currentSpots.length == 1){
      // console.log("currentSpots",currentSpots);
      const position = {
        lat: currentSpots[0].lat,
        lng: currentSpots[0].lng
      }
      map.panTo(position);
      map.setZoom(15)
    }
  },[currentSpots,map]);



  // useEffect(()=>{
  //   console.log("spots",spots);
  // },[spots]);
  
  const onLoad = useCallback((mapInstance) => {
    // console.log("on load!!",isMapLoaded)
    setMap(mapInstance);  // map 인스턴스 설정
    window.google.maps.event.addListenerOnce(mapInstance, 'idle', () => {
      setIsMapLoaded(true);
    });
  },[]);

  // useEffect(() => {
  //   if (map && spots && isMapLoaded) {
  //     // 마커 관련 작업 수행
  //     console.log("Map and spots are ready", spots);
  //   }
  // }, [map, spots, isMapLoaded]);

  const handleZoomChanged = useCallback(() => {
    // console.log("handle",map);
    if (map) {
      const newZoom = map.getZoom();
      setZoom(newZoom);
      // console.log('Current zoom level:', newZoom);
    }
  },[map]);

  

  const loadUserLocation = () => {
    if(!userLocation)setIsUserLocationLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setIsUserLocationLoading(false);
          const _userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          setUserLocation(_userLocation);
          map.panTo(_userLocation);
          map.setZoom(11);
        },
        (err) => console.error(err.message)
      );
    } else {
      alert("GPS가 지원되지 않는 브라우저입니다");
      setIsUserLocationLoading(false);
    }
  };

  const viewAll = () => {
    map.setZoom(defaultZoom);
    map.panTo(center);
    resetCurrentSpots();
  }

  // SVG 마커 생성 함수
  const createCircleTextMarkerIcon = (spot) => {
    const circleRadius = 6;
    const fontSize = 14;
    
    const height = 50;
    const name = isKorean?spot.category:spot.category_en;
    const width = name.length * fontSize * 0.7;
    return {
      url: `data:image/svg+xml,${encodeURIComponent(`
        <svg xmlns="http://www.w3.org/2000/svg" style="overflow: visible;" width="${width}" height="${height}">
          <circle 
            cx="${width/2}" 
            cy="${height - circleRadius}" 
            r="${circleRadius}" 
            fill="${spot.color}"
            stroke="#00000055"
            stroke-width="2"
          />
          ${zoom >= 14&&`<text 
            x="${width/2}" 
            y="${height - (fontSize+circleRadius)}" 
            text-anchor="middle" 
            dominant-baseline="middle"
            font-family="Arial" 
            font-size="${fontSize}px" 
            font-weight="bold"
            fill="black" 
            stroke="white" 
            stroke-width="3"
            stroke-linejoin="round"
            paint-order="stroke">${name}</text>`}
        </svg>
      `)}`,
      anchor: new window.google.maps.Point(width/2, height),
      scaledSize: new window.google.maps.Size(width, height)
    };
  };


  return (
    <div className="absolute overflow-hidden inset w-full h-full">
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={zoom}
          onLoad={onLoad}
          onZoomChanged={handleZoomChanged}
          options={{ disableDefaultUI: true, styles: silverStyle }}
        >
          {seoulPath&&<Polygon
            path={seoulPath}
            options={{
              strokeColor: "#00000066",
              strokeWeight: 2,
              fillOpacity:0,
            }}
          />}
          {/* Spots 표시 */}
          {isMapLoaded && spots && spots.map(spot =>{ 
            // console.log("spot",spot);
            return (
            <Marker
              key={spot.title}
              position={{ lat: spot.lat, lng: spot.lng }}
              title={spot.title}
              onClick={() => {
                setContentOpened(true);
                setSelectedSpot(spot);
              }}
              icon={createCircleTextMarkerIcon(spot)}
            />
          )})}
          {/* 유저 위치 마커 */}
          {userLocation && <Marker position={userLocation} />}
        </GoogleMap>
      <div className="absolute left-4 bottom-20 flex flex-row items-center justify-center">
        <button onClick={viewAll} className="px-4 py-2 flex flex-row items-center justify-center bg-white text-black text-sm font-semibold border-2 border-black active:bg-point-yellow active:text-black">
          <SVGIcon svgPath={url_icon_all} className="inline-block mr-2" style={{width:"15px",height:"15px"}} />
          <span>{isKorean?`전체`:`All`}</span>
        </button>
        <button onClick={loadUserLocation} className="px-4 py-2 flex flex-row items-center justify-center bg-white text-black text-sm font-semibold border-2 border-black ml-2 active:bg-point-yellow active:text-black">
          <SVGIcon svgPath={url_icon_user} className="inline-block mr-2" style={{width:"15px",height:"15px"}} />
          <span>{isKorean?`내 위치`:`My Location`}</span>
        </button>
      </div>
      {children}
      {isUserLocationLoading&&<div className="bg-white whitespace-nowrap border-2 border-black p-4 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">접속자 위치를 불러오는 중 입니다...</div>}
    </div>
  );
};

export default Map;